import styled from 'styled-components';

export const StyledBreadcrumb = styled.div`
    .breadcrumb {
        margin-bottom: 0;
        background: transparent;
        padding-left: 0;
        padding-right: 0;
        
        &-item {
            color: #1D2131;
            font-size: 14px;
            font-weight: 600;
            
            &.active {
                color: #545DA9;
                text-transform: uppercase;
            }
        }
    }
`;

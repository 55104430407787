import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BiMobile } from 'react-icons/bi';
import PropTypes from 'prop-types';
import {
  FormGroup, Label, Input, Button, FormFeedback, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  StyledResetForm,
} from './ResetPassword.form.styles';
import { ResetPassword } from '../../../store/actions/Workspaces';

function ResetPasswordForm({
  onSuccess,
  onClose,
  userId,
}) {
  // eslint-disable-next-line no-unused-vars
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(null);
  const initialValues = {
    phoneNumber: '',
  };

  const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegExp, t('Invalid phone number'))
      .required(t('Required')),
  });

  const handleSubmit = async (values) => {
    setProcessing(true);
    try {
      await ResetPassword({
        userId,
        phoneNumber: values.phoneNumber,
      });
      onSuccess();
    } catch (e) {
      setApiError(e.response.data.message);
    }
  };

  return (
    <StyledResetForm>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange, values, errors,
        }) => (
          <Form>
            <ModalBody>
              <p>{t('For verification, enter the phone number assigned to the selected user')}</p>
              <FormGroup className="position-relative">
                <Label for="phoneNumber">{t('Mobile number')}</Label>
                <Input
                  className="icon"
                  type="text"
                  id="phoneNumber"
                  placeholder={t('Enter mobile number')}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  invalid={errors.phoneNumber || apiError}
                />
                <BiMobile />
                <FormFeedback>{errors.phoneNumber || apiError}</FormFeedback>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onClose}
                type="button"
                color="danger"
                size="lg"
              >
                {t('Cancel')}
              </Button>

              <Button
                type="submit"
                color="primary"
                size="lg"
              >
                {t('Confirm')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </StyledResetForm>
  );
}

ResetPasswordForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  ResetPassword: (data) => dispatch(ResetPassword(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordForm);

import { TYPES } from '../../types';

// eslint-disable-next-line default-param-last
const WorkspacesReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.WORKSPACES.LIST:
      return { ...state, list: action.data };
    case TYPES.WORKSPACES.MEMBERS:
      return { ...state, members: action.data };
    case TYPES.WORKSPACES.SET_PROCESSING_WORKSPACE:
      return { ...state, processing: action.data };
    case TYPES.WORKSPACES.TYPES:
      return { ...state, types: action.data };
    case TYPES.WORKSPACES.UPDATE_PROCESSING_WORKSPACE:
      return {
        ...state,
        processing: {
          ...state.processing,
          workspaceType: {
            ...state.processing.workspaceType,
            name: action.data.name,
            id: action.data.id,
          },
        },
      };
    default:
      return state;
  }
};
export default WorkspacesReducer;

export default {
  BASE_PATH: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  PASSWORD_RESET: '/password-reset',
  DASHBOARD: '/dashboard',
  WORKSPACES: '/workspaces',
  ACCOUNT: '/account',
  SETTINGS: '/settings',
  NOT_FOUND: '/404',
  PAYMENTS: '/payments',
};

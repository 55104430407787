import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledPageTitle,
} from './PageTitle.styles';

function PageTitle({
  title,
}) {
  return (
    <StyledPageTitle>
      {title}
    </StyledPageTitle>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;

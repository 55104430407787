import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import {
  FormGroup, Label, Input, Button, FormFeedback, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from 'react-phone-number-input';
import {
  StyledInviteMembers, PhoneWrapper, PhoneError,
} from './InviteMember.form.styles';
import { InviteMember } from '../../../store/actions/Workspaces';
import { StyledInputInfo } from '../Login/Login.form.styles';

function InviteMemberForm({
  onSuccess,
  workspaceId,
}) {
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(null);
  const initialValues = {
    username: '',
  };

  const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .matches(phoneRegExp, t('Invalid phone number'))
      .required(t('Required')),
    adminPanelDescription: Yup.string().required(t('Required')),
  });

  const handleSubmit = async (values) => {
    setProcessing(true);
    try {
      const response = await InviteMember({
        workspaceId,
        username: values.username,
        firstName: '',
        lastName: '',
        adminPanelDescription: values.adminPanelDescription,
      });
      onSuccess(response.data.url);
    } catch (e) {
      setApiError(e.response.data.message);
    }
  };

  return (
    <StyledInviteMembers>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange, values, errors, touched, setFieldValue, setFieldTouched, isValid,
        }) => (
          <Form>
            <ModalBody>
              <FormGroup className="position-relative">
                <Label for="username">{t('Mobile number')}</Label>
                <PhoneWrapper>
                  <PhoneInput
                    international
                    defaultCountry="PL"
                    countryCallingCodeEditable={false}
                    placeholder={t('Enter mobile number')}
                    value={values.username}
                    onChange={(value) => {
                      setFieldValue('username', value);
                      setFieldTouched('username');
                    }}
                    error={touched.username && (errors.username || apiError)}
                  />
                </PhoneWrapper>
                {
                    touched.username && (errors.username || apiError) && (
                    <PhoneError>
                      {errors.username || apiError}
                    </PhoneError>
                    )
                }
              </FormGroup>

              <FormGroup className="position-relative">
                <Label for="username">{t('User description')}</Label>
                <Input
                  className="icon"
                  type="text"
                  id="adminPanelDescription"
                  placeholder={t('Enter user description')}
                  onChange={handleChange}
                  value={values.adminPanelDescription}
                  invalid={touched.adminPanelDescription && (errors.adminPanelDescription)}
                />
                <FaUserAlt />
                <StyledInputInfo>
                  <AiFillInfoCircle />
                  {t('That description will be not encrypted, make sure provided data is not sensitive')}
                </StyledInputInfo>
                <FormFeedback>{errors.username}</FormFeedback>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                color="primary"
                size="lg"
                disabled={processing || !isValid}
              >
                {t('Send invite')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </StyledInviteMembers>
  );
}

InviteMemberForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  InviteMember: (data) => dispatch(InviteMember(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteMemberForm);

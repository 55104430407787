import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getAxiosConfig = (config = {}, params = {}) => ({
  ...config,
  params,
  headers: {
    ...config.headers,
    'Content-Type': 'application/json',
    ...(localStorage.getItem('tokLokAccessToken')
      ? { Authorization: localStorage.getItem('tokLokAccessToken') }
      : {}
    ),
  },
});

const API = {
  get: async (url, params) => axiosInstance.get(url, getAxiosConfig({}, params)),
  post: async (url, data, config) => axiosInstance.post(url, data, getAxiosConfig(config)),
};

export {
  API,
};

import { TYPES } from '../../types';

// eslint-disable-next-line default-param-last
const CardsReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.PAYMENTS.CARDS:
      return { ...state, cards: action.data };
    case TYPES.PAYMENTS.INTENT:
      return { ...state, intent: action.data };
    default:
      return state;
  }
};
export default CardsReducer;

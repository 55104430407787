import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from '../components/common/PrivateRoute/PrivateRoute.component';
import routes from './config';

import LoginView from '../components/views/Login/Login.view';
import DashboardView from '../components/views/Dashboard/Dashboard.view';
import WorkspacesView from '../components/views/Workspaces/Workspaces.view';
import AccountView from '../components/views/Account/Account.view';
import SettingsView from '../components/views/Settings/Settings.view';
import PaymentsView from '../components/views/Payments/Payments.view';
// import RegisterView from '../components/views/Register/Register.view';
import PasswordResetView from '../components/views/PasswordReset/PasswordReset.view';

function RoutesComponent({
  isAuthorized,
}) {
  return (
    <Switch>
      <Route exact path={routes.BASE_PATH}>
        {isAuthorized
          ? <Redirect push to={routes.DASHBOARD} />
          : <Redirect push to={routes.LOGIN} />}
      </Route>
      <Route exact path={routes.LOGIN} component={LoginView} />
      {/* <Route exact path={routes.REGISTER} component={RegisterView} /> */}
      <Route exact path={routes.PASSWORD_RESET} component={PasswordResetView} />
      <PrivateRoute exact path={routes.DASHBOARD} component={DashboardView} />
      <PrivateRoute exact path={routes.WORKSPACES} component={WorkspacesView} />
      <PrivateRoute exact path={routes.ACCOUNT} component={AccountView} />
      <PrivateRoute exact path={routes.PAYMENTS} component={PaymentsView} />
      <PrivateRoute exact path={routes.SETTINGS} component={SettingsView} />
      <Redirect push to={routes.NOT_FOUND} />
    </Switch>
  );
}

RoutesComponent.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: !!state.Authorization.authorization_data,
});

export default connect(
  mapStateToProps,
  null,
)(RoutesComponent);

import { TYPES } from '../../types';

// eslint-disable-next-line default-param-last
const AuthorizationReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.AUTHORIZATION.LOGIN:
      return { ...state, authorization_data: action.data };
    case TYPES.AUTHORIZATION.LOGOUT:
      return { ...state, authorization_data: null };
    default:
      return state;
  }
};
export default AuthorizationReducer;

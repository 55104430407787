import { logger } from '../../../utils';
import { API } from '../../../utils/axios';
import { TYPES } from '../../types';

const AddPaymentMethod = (data) => async () => {
  try {
    const response = await API.post('chat/service/payments', data);
    return response;
  } catch (e) {
    logger.error(e);
    return null;
  }
};

const GetCards = (data) => async (dispatch) => {
  dispatch({
    type: TYPES.PAYMENTS.CARDS,
    data: {
      fetching: true,
      data: [],
    },
  });
  try {
    const response = await API.get('chat/service/cards', data);
    dispatch({
      type: TYPES.PAYMENTS.CARDS,
      data: {
        fetching: false,
        data: response.data,
      },
    });
  } catch (e) {
    logger.error(e);
    return null;
  }
};

const CreateIntentMethod = (data) => async (dispatch) => {
  dispatch({
    type: TYPES.PAYMENTS.INTENT,
    data: {
      fetching: true,
      data: {},
    },
  });
  try {
    const response = await API.post('chat/service/n_payments/payment', data);
    dispatch({
      type: TYPES.PAYMENTS.INTENT,
      data: {
        fetching: false,
        data: response.data,
      },
    });
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export {
  AddPaymentMethod,
  GetCards,
  CreateIntentMethod,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../router/config';

function PrivateRoute({ component: Component, isAuthorized, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthorized
          ? <Component {...props} />
          : <Redirect to={routes.LOGIN} />
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: !!state.Authorization.authorization_data,
});

export default connect(
  mapStateToProps,
  null,
)(PrivateRoute);

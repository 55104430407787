import styled from 'styled-components';

export const StyledAddPaymentMethodForm = styled.div`
    .card-input {
        border-radius: 4px;
        border: 2px solid #9EA4BC;
        display: flex;
        height: 64px;
        width: 100%;
    }
    
    .modal-body > div {
        width: 100%;
        padding: 32px 0;
        
        label {
            align-items: center;
            margin-bottom: 0;
        }
    }
`;

import { TYPES } from '../../types';
import { logger } from '../../../utils';
import { API } from '../../../utils/axios';

const GetUserWorkspaces = (username) => async (dispatch) => {
  dispatch({
    type: TYPES.WORKSPACES.LIST,
    data: {
      fetching: true,
      data: [],
    },
  });

  try {
    const response = await API.get(`chat/service/workspaces/user/${username}`);
    dispatch({
      type: TYPES.WORKSPACES.LIST,
      data: {
        fetching: false,
        data: response.data,
      },
    });
  } catch (e) {
    logger.error(e);
    return null;
  }
};

const GetWorkspaceTypes = () => async (dispatch) => {
  dispatch({
    type: TYPES.WORKSPACES.TYPES,
    data: {
      fetching: true,
      data: [],
    },
  });

  try {
    const response = await API.get('chat/service/workspaces/types');
    dispatch({
      type: TYPES.WORKSPACES.TYPES,
      data: {
        fetching: false,
        data: response.data,
      },
    });
  } catch (e) {
    logger.error(e);
    return null;
  }
};

const GetWorkspaceMembers = ({ workspaceId }) => async (dispatch) => {
  dispatch({
    type: TYPES.WORKSPACES.MEMBERS,
    data: {
      fetching: true,
      data: [],
    },
  });

  try {
    const response = await API.get(`chat/service/workspaces/${workspaceId}/members`);
    dispatch({
      type: TYPES.WORKSPACES.MEMBERS,
      data: {
        fetching: false,
        data: response.data.content,
      },
    });
  } catch (e) {
    logger.error(e);
  }
};

const InviteMember = async ({ workspaceId, ...data }) => {
  try {
    return await API.post(`chat/service/workspaces/${workspaceId}/user`, data);
  } catch (e) {
    logger.error(e);
    return e;
  }
};

const BlockMember = async ({ userId }) => {
  try {
    return API.post(`chat/service/users/${userId}/block`);
  } catch (e) {
    logger.error(e);
    return e;
  }
};

const UnblockMember = async ({ userId }) => {
  try {
    return API.post(`chat/service/users/${userId}/unblock`);
  } catch (e) {
    logger.error(e);
    return e;
  }
};

const ResetPassword = async (data) => API.post('chat/service/users/resetPassword', data);

const SetProcessingWorkspace = (workspace) => async (dispatch) => {
  dispatch({
    type: TYPES.WORKSPACES.SET_PROCESSING_WORKSPACE,
    data: workspace,
  });
};

const UpdateProcessingWorkspace = (data) => async (dispatch) => {
  dispatch({
    type: TYPES.WORKSPACES.UPDATE_PROCESSING_WORKSPACE,
    data,
  });
};

export {
  GetUserWorkspaces,
  GetWorkspaceMembers,
  SetProcessingWorkspace,
  UpdateProcessingWorkspace,
  GetWorkspaceTypes,

  InviteMember,
  BlockMember,
  UnblockMember,
  ResetPassword,
};

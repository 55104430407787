import styled from 'styled-components';

export const StyledFooter = styled.footer`
    background: transparent linear-gradient(180deg, #1D2131 0%, #545DA9 100%) 0% 0% no-repeat padding-box;
    height: 96px;
    width: 100%;
    margin-top: auto;
    z-index: 1;
`;

export const StyledOwner = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 96px;
    
    span {
        color: #FFFFFF;
        font-size: 14px;
    }
`;

export const StyledLogo = styled.div`
    display: flex;
    align-items: center;
    height: 96px;
    
    img {
        margin-right: 19px;
    }
    
    h1 {
        font-size: 14px;
        display: inline-block;
        margin: 0;
        line-height: 26px;
        vertical-align: top;
        color: #FFFFFF;
        font-weight: 600;
    }
`;

import styled from 'styled-components';

export const StyledLoginForm = styled.div`
    .btn {
        margin: 0;
    }
    
    .send-verification-button {
        margin-bottom: 2rem;
    }
`;

export const StyledInputInfo = styled.div`
    svg {
        margin-right: .5rem;
    }
    
    font-size: .75rem;
    color: #9EA4BC;
    margin-top: .25rem;
`;

export const StyledSendVerificationButton = styled.div`
    .btn {
        margin: 0;
    }
    margin-bottom: 1rem;
`;

import styled from 'styled-components';

export const StyledWorkspace = styled.div`
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    width: 100%;
    margin-top: 1.5rem;
    padding: 1.125rem 2rem;
    
    .block-button {
        font-size: .75rem;
        font-weight: normal;
    }
    
    .nav-link {
        &__dropdown {
            text-decoration: none;
        }
    }
`;

export const StyledWorkspaceRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledWorkspaceSection = styled.div`

`;

export const StyledWorkspaceSectionHeader = styled.div`
    color: #545DA9;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0px;
`;

export const StyledWorkspaceSectionValue = styled.div`
    font: normal normal normal 16px Poppins;
    letter-spacing: 0px;
    color: #1D2131;
`;

export const StyledDetailsLine = styled.hr`
    border-top: 1px solid #E0E0E0;
    width: 100%;
`;

export const StyledWorkspaceMember = styled.div`
    border-bottom: 1px solid #E0E0E0;
    padding: .25rem 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    &:last-child {
        border-bottom: 0;
    }
    
    span {
        i {
            margin-left: 2rem;
            font-size: .75rem;
        }
    }
`;

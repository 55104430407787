import React, { useEffect } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoginImage } from '../../../assets/illustrations-logowanie-2.svg';
import {
  StyledPasswordResetView,
  StyledStyledPasswordImage,
  StyledStyledPasswordFormWrapper,
  StyledTitle,
  StyledSubtitle,
} from './PasswordReset.styles';

function PasswordResetView({
  isAuthorized,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (isAuthorized) {
      history.push('/workspaces');
    }
  }, [isAuthorized]);

  return (
    <StyledPasswordResetView>
      <Container fluid>
        <Row className="fill-page">
          <Col xs={12} md={6} className="d-none d-md-block">
            <StyledStyledPasswordImage>
              <LoginImage />
            </StyledStyledPasswordImage>
          </Col>

          <Col xs={12} md={6}>
            <StyledStyledPasswordFormWrapper>
              <StyledTitle>{t('Password reset')}</StyledTitle>
              <StyledSubtitle>{t('Keep your password safe')}</StyledSubtitle>
              {/* <PasswordResetForm /> */}
            </StyledStyledPasswordFormWrapper>
          </Col>
        </Row>
      </Container>
    </StyledPasswordResetView>
  );
}

PasswordResetView.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: !!state.Authorization.authorization_data,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetView);

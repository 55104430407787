import styled from 'styled-components';

export const StyledInviteMembers = styled.div`
    .btn {
        margin: 0;
    }
`;

export const PhoneWrapper = styled.div`
    border-radius: 4px;
    border: 2px solid #9EA4BC;
    display: flex;
    height: 64px;
    
    .PhoneInputCountry {
        padding: 0 16px;
        margin: 0;
    }
    
    .PhoneInputCountryIcon  {
        margin-right: 5px;
    }
    
    input {
        font-family: 'Poppins', sans-serif;
        color: #495057;
        border: none !important;
        outline: none !important;
    }
`;

export const PhoneError = styled.div`
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`;

export const TYPES = {
  AUTHORIZATION: {
    LOGIN: 'AUTHORIZATION/LOGIN',
    LOGOUT: 'AUTHORIZATION/LOGOUT',
  },
  WORKSPACES: {
    LIST: 'WORKSPACES/LIST',
    TYPES: 'WORKSPACES/TYPES',
    MEMBERS: 'WORKSPACES/MEMBERS',
    SET_PROCESSING_WORKSPACE: 'WORKSPACES/SET_PROCESSING_WORKSPACE',
    UPDATE_PROCESSING_WORKSPACE: 'WORKSPACES/UPDATE_PROCESSING_WORKSPACE',
  },
  PAYMENTS: {
    CARDS: 'PAYMENTS/CARDS',
    INTENT: 'PAYMENTS/INTENT',
  },
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
// AiFillHome,
// RiAccountCircleFill,
// AiTwotoneSetting,
} from 'react-icons/all';
import { GiDesk } from 'react-icons/gi';
import {
  Container,
  Button,
  Navbar,
  Col,
  Row,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  // NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
import { Logout } from '../../../store/actions/Authorization';
import Breadcrumb from '../Breadcrumb/Breadcrumb.component';
import Logo from '../../../assets/tok-lok-logo-white.png';
import routes from '../../../router/config';
import {
  StyledNavbar,
  StyledSubNavbar,
  Welcome,
  StyledPageBreadcrumb,
} from './Header.styles';

function Header({
  Logout,
  isAuthorized,
  username,
}) {
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(true);
  const [activeNavItem, setActiveNavItem] = useState('');
  const { t } = useTranslation();
  // const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);

  // const handleSignIn = () => {
  //   history.push(routes.LOGIN);
  // };
  //
  // const handleSignUp = () => {
  //   history.push(routes.REGISTER);
  // };

  const handleSignOut = () => {
    Logout();
  };

  const handleActiveLink = (match, location) => {
    setActiveNavItem(location.pathname);
  };

  // const handleActiveNavbar = (path) => history.location.pathname === path;

  return (
    <>
      <StyledNavbar>
        <Navbar expand="md">
          <Container>
            <NavbarBrand href={routes.BASE_PATH} className="mr-auto">
              <img src={Logo} alt="TokLok" width="39" height="39" />
              <h1>{t('TokLok')}</h1>
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                {/* <NavItem> */}
                {/*  <NavLink href="#">{t('Blog')}</NavLink> */}
                {/* </NavItem> */}
                {/* <NavItem> */}
                {/*  <NavLink href="#">{t('Advantages')}</NavLink> */}
                {/* </NavItem> */}
                {/* <NavItem> */}
                {/*  <NavLink href="#">{t('Technologies')}</NavLink> */}
                {/* </NavItem> */}
                {/* <NavItem> */}
                {/*  <NavLink href="#">{t('For whom')}</NavLink> */}
                {/* </NavItem> */}
                {/* <NavItem> */}
                {/*  <NavLink href="#">{t('Contact us')}</NavLink> */}
                {/* </NavItem> */}
              </Nav>
            </Collapse>
            {/* { */}
            {/*  !isAuthorized && ( */}
            {/*    <> */}
            {/*      <Button */}
            {/*        className="auth-button" */}
            {/*        color="primary" */}
            {/*        size="lg" */}
            {/*        onClick={handleSignIn} */}
            {/*      > */}
            {/*        {t('Sign in')} */}
            {/*      </Button> */}
            {/*      <Button */}
            {/*        className="auth-button" */}
            {/*        color="primary" */}
            {/*        outline */}
            {/*        size="lg" */}
            {/*        onClick={handleSignUp} */}
            {/*      > */}
            {/*        {t('Sign up')} */}
            {/*      </Button> */}
            {/*    </> */}
            {/*  ) */}
            {/* } */}

            {
              isAuthorized && (
              <>
                <Welcome>
                  {t('Hello', { username })}
                </Welcome>
                <Button
                  className="auth-button"
                  color="primary"
                  outline
                  size="lg"
                  onClick={handleSignOut}
                >
                  {t('Log out')}
                </Button>
              </>
              )
            }

            {/* <UncontrolledDropdown> */}
            {/*  <DropdownToggle nav caret> */}
            {/*    {t('EN')} */}
            {/*  </DropdownToggle> */}
            {/*  <DropdownMenu right> */}
            {/*    <DropdownItem> */}
            {/*      {t('PL')} */}
            {/*    </DropdownItem> */}
            {/*  </DropdownMenu> */}
            {/* </UncontrolledDropdown> */}
          </Container>
        </Navbar>
      </StyledNavbar>

      <StyledSubNavbar>
        <Container>
          <Row>
            <Col>
              {
                !isAuthorized
                  ? <Breadcrumb active={activeNavItem} />
                  : (
                    <Nav>
                      {/* <NavItem active={activeNavItem === routes.DASHBOARD}> */}
                      {/*  <NavLink isActive={handleActiveLink}
                      className="nav-link" to={routes.DASHBOARD}> */}
                      {/*    <AiFillHome /> */}
                      {/*    {t('Dashboard')} */}
                      {/*  </NavLink> */}
                      {/* </NavItem> */}
                      <NavItem active={activeNavItem === routes.WORKSPACES}>
                        <NavLink isActive={handleActiveLink} className="nav-link" to={routes.WORKSPACES}>
                          <GiDesk />
                          {t('Workspaces')}
                        </NavLink>
                      </NavItem>
                      {/*  <NavItem active={activeNavItem === routes.ACCOUNT}> */}
                      {/*    <NavLink isActive={handleActiveLink}
                      className="nav-link" to={routes.ACCOUNT}> */}
                      {/*      <RiAccountCircleFill /> */}
                      {/*      {t('Account')} */}
                      {/*    </NavLink> */}
                      {/*  </NavItem> */}
                      {/*  <NavItem active={activeNavItem === routes.SETTINGS}> */}
                      {/*    <NavLink isActive={handleActiveLink}
                      className="nav-link" to={routes.SETTINGS}> */}
                      {/*      <AiTwotoneSetting /> */}
                      {/*      {t('Settings')} */}
                      {/*    </NavLink> */}
                      {/*  </NavItem> */}
                    </Nav>
                  )
              }
            </Col>
          </Row>
        </Container>
      </StyledSubNavbar>

      {
        isAuthorized && (
        <StyledPageBreadcrumb>
          <Container>
            <Row>
              <Col xs={12}>
                <Breadcrumb active={activeNavItem} />
              </Col>
            </Row>
          </Container>
        </StyledPageBreadcrumb>
        )
      }
    </>
  );
}

Header.defaultProps = {
  username: undefined,
};

Header.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  Logout: PropTypes.func.isRequired,
  username: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthorized: !!state.Authorization.authorization_data,
  username: state.Authorization.authorization_data
      && state.Authorization.authorization_data.username,
});

const mapDispatchToProps = (dispatch) => ({
  Logout: () => dispatch(Logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

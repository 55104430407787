import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col, Alert, ModalHeader, ModalBody, Modal, ModalFooter, Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoginImage } from '../../../assets/illustrations-logowanie-2.svg';
import LoginForm from '../../forms/Login/Login.form';
// import routes from '../../../router/config';
import {
  StyledLoginView,
  StyledLoginImage,
  StyledLoginFormWrapper,
  StyledTitle,
  StyledSubtitle,
  AccountInformation,
} from './Login.styles';

function LoginView({
  isAuthorized,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isPaymentSuccessModal, setIsPaymentSuccessModal] = useState(false);

  const paymentStatus = params.get('payment');

  useEffect(() => {
    switch (paymentStatus) {
      case 'success':
        setIsPaymentError(false);
        setIsPaymentSuccess(true);
        setIsPaymentSuccessModal(true);
        break;
      case 'error':
        setIsPaymentError(true);
        setIsPaymentSuccess(false);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      history.push('/workspaces');
    }
  }, [isAuthorized]);

  return (
    <StyledLoginView>
      <Container fluid>
        <Row className="fill-page">
          <Col xs={12} md={6} className="d-none d-md-block">
            <StyledLoginImage>
              <LoginImage />
            </StyledLoginImage>
          </Col>

          <Col xs={12} md={6}>
            <StyledLoginFormWrapper>
              {
                isPaymentSuccess && (
                <Alert color="success">
                  {t('Your payment has been accepted. You will receive the details by SMS in a moment. Please continue next steps on your mobile device.')}
                </Alert>
                )
              }

              {
                isPaymentError && (
                <Alert color="danger">
                  {t('Your payment has been declined.')}
                </Alert>
                )
              }
              <StyledTitle>{t('Log in')}</StyledTitle>
              <StyledSubtitle>{t('Log in with your data that you entered during your registration')}</StyledSubtitle>
              <LoginForm />
              <AccountInformation>
                {/* {t('Do not have an account?')} */}
                {/* <Link to={routes.REGISTER}>{t('Sign up')}</Link> */}
                {/*
                <Link className="d-block" to={routes.PASSWORD_RESET}>{t('Forget password?')}</Link>
                */}
              </AccountInformation>
            </StyledLoginFormWrapper>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={isPaymentSuccessModal}
      >
        <ModalHeader>
          {t('Payment confirmation')}
        </ModalHeader>
        <ModalBody>
          <p>{t('We sent a text message to your mobile phone with a link to the next steps. Continue on your mobile device before logging into the administration panel')}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            size="lg"
            onClick={() => setIsPaymentSuccessModal(false)}
          >
            {t('Got it')}
          </Button>
        </ModalFooter>
      </Modal>
    </StyledLoginView>

  );
}

LoginView.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: !!state.Authorization.authorization_data,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginView);

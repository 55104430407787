import { TYPES } from '../../types';
import { logger } from '../../../utils';
import { API } from '../../../utils/axios';

const Login = (data) => async (dispatch) => {
  try {
    const response = await API.post('/login', data);
    if (response.status === 200) {
      localStorage.setItem('tokLokAccessToken', response.data.Authorization);

      dispatch({
        type: TYPES.AUTHORIZATION.LOGIN,
        data: { access_token: response.data.Authorization, username: data.username },
      });
    }
  } catch (e) {
    logger.error(e);
    return null;
  }
};

const Logout = () => async (dispatch) => {
  localStorage.removeItem('tokLokAccessToken');
  try {
    dispatch({
      type: TYPES.AUTHORIZATION.LOGOUT,
    });
  } catch (e) {
    logger.error(e);
  }
};

const SendVerificationCode = async (data) => API.post('/code', data);

const GetWorkspaceId = async (clientName, workspaceName) => {
  try {
    const response = await API.get(`/chat/service/workspaces/${clientName}/${workspaceName}`);
    return response.data;
  } catch (e) {
    return null;
  }
};

export {
  Login,
  Logout,

  GetWorkspaceId,
  SendVerificationCode,
};

import styled from 'styled-components';

export const StyledPayments = styled.div`
    .add-payment-method {
        svg {
            margin-right: 16px;
            margin-top: -2px;
        }
    }
`;

export const StyledPaymentsTable = styled.div`
    margin-top: 1.5rem;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    width: 100%;
    padding: 1.125rem 2rem;
    
    table {
        width: 100%;
        
        td {
            color: #545DA9;
            font: normal normal 600 16px Poppins;
            letter-spacing: 0px;
        }
    }
`;

export const StyledTypes = styled.div`
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    width: 100%;
    padding: 1.125rem 2rem;
    
    table {
        width: 100%;
        
        th {
            color: #545DA9;
            font: normal normal 600 16px Poppins;
            letter-spacing: 0px;
        }
    }
`;

export const StyledCardNumber = styled.span`

`;

import styled from 'styled-components';

export const StyledPageTitle = styled.h2`
    color: #545DA9;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 5px solid #545DA9;
    display: inline-flex;
    padding-bottom: 8px;
`;

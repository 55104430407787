export const initialStore = {
  Authorization: {},
  Workspaces: {
    list: {
      fetching: true,
      data: [],
    },
    members: {
      fetching: true,
      data: [],
    },
    processing: {},
    types: [],
  },
  Payments: {
    cards: {
      fetching: false,
      data: [],
    },
    intent: {
      fetching: false,
      data: {},
    },
  },
};

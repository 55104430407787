import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ModalBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  Elements, useElements, useStripe, PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import {
  StyledAddPaymentMethodForm,
} from './AddPaymentMethod.form.styles';
import { CreateIntentMethod } from '../../../store/actions/Payments';

function CheckoutForm() {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      toast.error(error.message);
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="text-right">
      <PaymentElement />
      <Button
        type="submit"
        color="primary"
        outline
        size="lg"
        disabled={!stripe || isProcessing}
        className="text-right mr-0 mt-3"
      >
        {
          isProcessing && (
          <div className="lds-ellipsis lds-ellipsis-button">
            <div />
            <div />
            <div />
            <div />
          </div>
          )
        }
        {
          !isProcessing && (
            t('Zapisz')
          )
        }
      </Button>
    </form>
  );
}

function AddPaymentMethodForm({
  CreateIntentMethod,
  processing,
  paymentIntent,
}) {
  const { t } = useTranslation();
  const stripe = loadStripe('pk_test_51LMyJWEDAZ2rF06JUXqvBFHFLYAT4hLSKartn2FlJocOhrz7xnRitaayUjXlvgbvxp0Nri85SLpoDWduAjHPi8as005v08t3Mu');

  useEffect(() => {
    CreateIntentMethod({
      workspaceTypeId: processing.workspaceType.id,
      productId: processing.workspaceType.id,
    });
  }, []);

  const options = {
    clientSecret: paymentIntent.data.clientSecret,
  };

  return (
    <StyledAddPaymentMethodForm>
      <ModalBody>
        <p className="d-flex justify-content-center small">{t('We do not store your data - all data is handled by payment processor, and we will only present the history of your payments using the last 4 digits of a card and the dates.')}</p>

        {
            paymentIntent.data.clientSecret && !paymentIntent.fetching && (
            <Elements stripe={stripe} options={options}>
              <CheckoutForm />
            </Elements>
            )
        }

        {
          paymentIntent.fetching && (
          <div className="text-center">
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          )
        }

        <p className="small mt-4">{t('By clicking the "Save" button, you consent to the immediate performance of the contract. It is not possible to immediately withdraw from the contract and refund your money. TokLok will automatically renew your workspace subscriptions and charge you with the selected payment method indicated in the administration panel under "Next payment amount" until you cancel the service. You can cancel the service at any time to stop future payments.')}</p>
      </ModalBody>
    </StyledAddPaymentMethodForm>
  );
}

AddPaymentMethodForm.defaultProps = {
  paymentIntent: {
    data: {},
    fetching: false,
  },
};

AddPaymentMethodForm.propTypes = {
  CreateIntentMethod: PropTypes.func.isRequired,
  processing: PropTypes.object.isRequired,
  paymentIntent: PropTypes.object,
};

const mapStateToProps = (state) => ({
  processing: state.Workspaces.processing,
  username: state.Authorization.authorization_data.username,
  paymentIntent: state.Payments.intent,
});

const mapDispatchToProps = (dispatch) => ({
  CreateIntentMethod: (data) => dispatch(CreateIntentMethod(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPaymentMethodForm);

import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody, Alert,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import {
  GetUserWorkspaces,
  GetWorkspaceMembers,
  BlockMember,
  UnblockMember,
  SetProcessingWorkspace,
} from '../../../store/actions/Workspaces';
import PageTitle from '../../common/PageTitle/PageTitle.component';
import {
  StyledWorkspace,
  StyledWorkspaceSection,
  StyledWorkspaceSectionHeader,
  StyledWorkspaceSectionValue,
  StyledWorkspaceRow,
  StyledDetailsLine,
  StyledWorkspaceMember,
} from './Workspaces.styles';
import InviteMemberForm from '../../forms/InviteMember/InviteMember.form';
import ResetPasswordForm from '../../forms/ResetPassword/ResetPassword.form';
import routes from '../../../router/config';

function WorkspacesView({
  username,
  workspaces,
  members,
  GetUserWorkspaces,
  GetWorkspaceMembers,
  SetProcessingWorkspace,
}) {
  const { t } = useTranslation();
  const [workspaceDropdown, setWorkspaceDropdown] = useState(null);
  const [workspaceDetails, setWorkspaceDetails] = useState(null);
  const [invitationWorkspaceId, setInvitationWorkspaceId] = useState(null);
  const [processingUserId, setProcessingUserId] = useState(null);
  const [resetProcessingUserId, setResetProcessingUserId] = useState(null);
  const [isInvitationModalVisible, setIsInvitationModalVisible] = useState(false);
  const [isBlockingModalVisible, setIsBlockingModalVisible] = useState(false);
  const [isUnblockingModalVisible, setIsUnblockingModalVisible] = useState(false);
  const [isSuccessInvitationModalVisible, setSuccessInvitationModalVisible] = useState(false);
  const [resetPasswordConfirmationVisible, setResetPasswordConfirmationVisible] = useState(false);
  const [invitationLink, setInvitationLink] = useState('');

  useEffect(() => {
    GetUserWorkspaces(username);
  //   SetProcessingWorkspace(null);
  }, []);

  const handleDropdownToggle = (workspaceId) => {
    setWorkspaceDropdown(workspaceDropdown === workspaceId ? null : workspaceId);
  };

  const handleShowWorkspaceDetails = (workspaceId) => {
    if (workspaceId === workspaceDetails) {
      setWorkspaceDetails(null);
    } else {
      setWorkspaceDetails(workspaceId);
      GetWorkspaceMembers({ workspaceId });
    }
  };

  const handleInviteMember = (workspaceId) => {
    setIsInvitationModalVisible(!isInvitationModalVisible);
    setInvitationWorkspaceId(workspaceId);
  };

  const handleBlockMember = (userId) => {
    setProcessingUserId(userId);
    setIsBlockingModalVisible(!isBlockingModalVisible);
  };

  const handleUnblockMember = (userId) => {
    setProcessingUserId(userId);
    setIsUnblockingModalVisible(!isUnblockingModalVisible);
  };

  const handleBlockMemberAction = async () => {
    await BlockMember({ userId: processingUserId });
    GetWorkspaceMembers({ workspaceId: workspaceDetails });
    handleBlockMember(null);
  };

  const handleUnblockMemberAction = async () => {
    await UnblockMember({ userId: processingUserId });
    GetWorkspaceMembers({ workspaceId: workspaceDetails });
    handleUnblockMember(null);
  };

  const handleInvitationSuccessModalToggle = () => {
    setSuccessInvitationModalVisible(!isSuccessInvitationModalVisible);
  };

  const handleResetPasswordModalToggle = (userId) => {
    setResetProcessingUserId(userId);
    setResetPasswordConfirmationVisible(!resetPasswordConfirmationVisible);
  };

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <PageTitle title={t('Workspaces')} />
        </Col>
      </Row>
      {/* CZAT-669 */}
      {/* <Row> */}
      {/*  <Col xs={12} className="text-right"> */}
      {/*    <Button */}
      {/*      style={{ margin: 0 }} */}
      {/*      type="secondary" */}
      {/*      outline */}
      {/*      size="lg" */}
      {/*    > */}
      {/*      {t('Create new workspace')} */}
      {/*    </Button> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row>
        {
            workspaces.data.map((workspace) => {
              const nextPaymentDate = format(
                new Date(
                  workspace.dueDate.year,
                  workspace.dueDate.monthOfYear - 1,
                  workspace.dueDate.dayOfMonth,
                ),
                'dd.MM.yyyy',
              );
              return (
                <Col xs={12} key={workspace.id}>
                  <StyledWorkspace>
                    <StyledWorkspaceRow>
                      <StyledWorkspaceSection>
                        <StyledWorkspaceSectionHeader>
                          {t('Name')}
                        </StyledWorkspaceSectionHeader>
                        <StyledWorkspaceSectionValue>
                          {workspace.name}
                        </StyledWorkspaceSectionValue>
                      </StyledWorkspaceSection>

                      <StyledWorkspaceSection>
                        <StyledWorkspaceSectionHeader>
                          {t('Users')}
                        </StyledWorkspaceSectionHeader>
                        <StyledWorkspaceSectionValue>
                          {`${workspace.numberOfUsers} / ${workspace.workspaceType.usersLimit}`}
                        </StyledWorkspaceSectionValue>
                      </StyledWorkspaceSection>

                      <StyledWorkspaceSection>
                        <StyledWorkspaceSectionHeader>
                          {t('Next payment in')}
                        </StyledWorkspaceSectionHeader>
                        <StyledWorkspaceSectionValue>
                          {nextPaymentDate}
                        </StyledWorkspaceSectionValue>
                      </StyledWorkspaceSection>

                      <StyledWorkspaceSection>
                        <StyledWorkspaceSectionHeader>
                          {t('Next payment amount')}
                        </StyledWorkspaceSectionHeader>
                        <StyledWorkspaceSectionValue>
                          {workspace.workspaceType.amount}
                        </StyledWorkspaceSectionValue>
                      </StyledWorkspaceSection>

                      <StyledWorkspaceSection>
                        <StyledWorkspaceSectionHeader>
                          {t('Subscription name')}
                        </StyledWorkspaceSectionHeader>
                        <StyledWorkspaceSectionValue>
                          {workspace.workspaceType.name}
                        </StyledWorkspaceSectionValue>
                      </StyledWorkspaceSection>

                      <StyledWorkspaceSection>
                        <Dropdown
                          isOpen={workspaceDropdown === workspace.id}
                          toggle={() => handleDropdownToggle(workspace.id)}
                        >
                          <DropdownToggle color="secondary" outline>
                            <BsThreeDotsVertical />
                          </DropdownToggle>
                          <DropdownMenu>
                            <NavLink to={routes.PAYMENTS} className="nav-link__dropdown" onClick={() => SetProcessingWorkspace(workspace)}>
                              <DropdownItem>{t('Payments')}</DropdownItem>
                            </NavLink>
                            <DropdownItem onClick={() => handleShowWorkspaceDetails(workspace.id)}>{workspaceDetails === workspace.id ? t('Hide details') : t('Show details')}</DropdownItem>
                            <DropdownItem onClick={() => handleInviteMember(workspace.id)}>{t('Invite member')}</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </StyledWorkspaceSection>
                    </StyledWorkspaceRow>

                    {
                      workspaceDetails === workspace.id && (
                      <>
                        <StyledDetailsLine />
                        {
                          members.data.map((member) => (
                            <StyledWorkspaceMember key={member.id}>
                              <span>
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {`${member.adminPanelDescription ? member.adminPanelDescription : (member.firstName || member.lastName) ? `${member.firstName} ${member.lastName}` : t('No user description set')}`}
                                <i>{`${member.active ? t('This user is active') : t('This user is inactive')}`}</i>
                              </span>

                              <span className="ml-auto" />

                              {
                                !member.active && (
                                <Button
                                  onClick={() => handleUnblockMember(member.id)}
                                  color="success"
                                  size="sm"
                                  className="block-button"
                                >
                                  {t('Unblock user')}
                                </Button>
                                )
                              }

                              {
                                member.active && (
                                <Button
                                  onClick={() => handleBlockMember(member.id)}
                                  color="danger"
                                  size="sm"
                                  className="block-button"
                                >
                                  {t('Block user')}
                                </Button>
                                )
                              }

                              <Button
                                onClick={() => handleResetPasswordModalToggle(member.id)}
                                color="primary"
                                size="sm"
                                className="block-button"
                              >
                                {t('Reset password')}
                              </Button>

                            </StyledWorkspaceMember>
                          ))
                        }
                      </>
                      )
                    }
                  </StyledWorkspace>
                </Col>
              );
            })
          }
      </Row>

      <Modal
        isOpen={isInvitationModalVisible}
        toggle={handleInviteMember}
      >
        <ModalHeader
          toggle={handleInviteMember}
        >
          {t('Invite member')}
        </ModalHeader>
        <InviteMemberForm
          workspaceId={invitationWorkspaceId}
          onSuccess={(response) => {
            handleInviteMember();
            setInvitationLink(response);
            handleInvitationSuccessModalToggle();
          }}
        />
      </Modal>

      <Modal
        isOpen={isBlockingModalVisible}
        toggle={handleBlockMember}
      >
        <ModalHeader
          toggle={handleBlockMember}
        >
          {t('Confirmation')}
        </ModalHeader>
        <ModalBody>
          <p>{t('Are you sure you would like to block selected user?')}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleBlockMember}
            type="submit"
            color="secondary"
            outline
            size="lg"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleBlockMemberAction}
            type="submit"
            color="primary"
            size="lg"
          >
            {t('Confirm')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isUnblockingModalVisible}
        toggle={handleUnblockMember}
      >
        <ModalHeader
          toggle={handleUnblockMember}
        >
          {t('Confirmation')}
        </ModalHeader>
        <ModalBody>
          <p>{t('Are you sure you would like to unblock selected user?')}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleUnblockMember}
            type="submit"
            color="secondary"
            outline
            size="lg"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleUnblockMemberAction}
            type="submit"
            color="primary"
            size="lg"
          >
            {t('Confirm')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isSuccessInvitationModalVisible}
        toggle={handleInvitationSuccessModalToggle}
      >
        <ModalHeader
          toggle={handleInvitationSuccessModalToggle}
        >
          {t('Invitation')}
        </ModalHeader>
        <ModalBody>
          <p>{t('Your invitation link. Share it with user which you would like to invite.')}</p>
          <Alert color="success" isOpen fade={false}>
            {invitationLink}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleInvitationSuccessModalToggle}
            type="button"
            color="primary"
            size="lg"
          >
            {t('Close')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={resetPasswordConfirmationVisible}
        toggle={handleResetPasswordModalToggle}
      >
        <ModalHeader
          toggle={handleResetPasswordModalToggle}
        >
          {t('Reset password')}
        </ModalHeader>
        <ModalBody>
          <ResetPasswordForm
            userId={resetProcessingUserId}
            onSuccess={() => {
              setResetProcessingUserId(null);
              handleResetPasswordModalToggle();
              toast.success(t('Password reset correctly.'));
            }}
            onClose={handleResetPasswordModalToggle}
          />
        </ModalBody>
      </Modal>
    </Container>
  );
}

WorkspacesView.propTypes = {
  GetUserWorkspaces: PropTypes.func.isRequired,
  GetWorkspaceMembers: PropTypes.func.isRequired,
  SetProcessingWorkspace: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  workspaces: PropTypes.object.isRequired,
  members: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  workspaces: state.Workspaces.list,
  username: state.Authorization.authorization_data.username,
  members: state.Workspaces.members,
});

const mapDispatchToProps = (dispatch) => ({
  GetUserWorkspaces: (data) => dispatch(GetUserWorkspaces(data)),
  GetWorkspaceMembers: (data) => dispatch(GetWorkspaceMembers(data)),
  SetProcessingWorkspace: (data) => dispatch(SetProcessingWorkspace(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkspacesView);

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/tok-lok-logo-white.png';
import {
  StyledFooter,
  StyledOwner,
  StyledLogo,
} from './Footer.styles';

function Footer() {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col>
            <StyledLogo>
              <img src={Logo} alt="TokLok" width={20} height={20} />
              <h1>{t('TokLok')}</h1>
            </StyledLogo>
          </Col>
          <Col>
            <StyledOwner>
              <span>
                {`Reiba Ltd | ® ${t('All rights reserved')} ${new Date().getFullYear()}`}
              </span>
            </StyledOwner>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
}

export default Footer;

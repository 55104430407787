import styled from 'styled-components';

export const StyledNavbar = styled.header`
    width: 100%;
    background: transparent linear-gradient(180deg, #1D2131 0%, #545DA9 100%) 0% 0% no-repeat padding-box;
    padding: 13px 0;
    
    .auth-button {
        width: 192px;
    }
    
    .navbar-brand {
        img {
            margin-right: 19px;
        }
        
        h1 {
            font-size: 24px;
            display: inline-block;
            margin: 0;
            line-height: 40px;
            vertical-align: top;
            color: rgb(255, 255, 255);
            font-weight: bold;
        }
    }
    
    .navbar-collapse {
        flex-grow: 0;
    }
    
    .nav-item,
    .dropdown { 
        .nav-link {
            color: #FFFFFF;
            font-size: 18px;
            font-weight: normal;
        }
    }
`;

export const StyledSubNavbar = styled.div`
    height: 87px;
    box-shadow: 0px 0px 15px #545DA924;
    border: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    
    .nav {
        display: flex;
        justify-content: space-between;
        
        .nav-item {
            font-size: 18px;
            font-weight: 600;
            width: 25%;
            display: flex;
            justify-content: center;
            position: relative;
            height: 87px;
            align-items: center;
            
            &:before {
                border-right: 2px solid #E0E0E0;
                display: block;
                content: '';
                height: 29px;
                right: 0;
                position: absolute;
            }
            
            &:last-child {
                &:before {
                    display: none;
                }
            }
            
            &.active {
                &:after {
                    position: absolute;
                    border-bottom: 3px solid #545DA9;
                    display: block;
                    content: '';
                    width: 100%;
                    bottom: 0;
                }
                
                .nav-link {
                    color: #545DA9;
                }
            }
            
            .nav-link {
                color: #9EA4BC;
                padding: 0 10px;
                text-transform: uppercase;
                
                svg {
                    width: 26px;
                    height: 26px;
                    margin-right: 20px;
                }
            }
        }
    }
`;

export const Welcome = styled.p`
    margin-bottom: 0;
    margin-right: 26px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
`;

export const StyledPageBreadcrumb = styled.div`
    margin: 20px 0;
`;

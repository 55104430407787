const logger = {
  // eslint-disable-next-line no-console
  info: (message) => (process.env.NODE_ENV === 'development' ? console.info(`%c🛈 ${message} `, 'background: #FFFFFF; color: #0707FF') : () => {}),
  // eslint-disable-next-line no-console
  error: (message) => (process.env.NODE_ENV === 'development' ? console.error(`%c⦻ ${message} `, 'background: #FF0000; color: #FFFFFF') : () => {}),
};

export {
  logger,
};

import { combineReducers } from 'redux';
import Authorization from './Authorization';
import Workspaces from './Workspaces';
import Payments from './Payments';

export default combineReducers({
  Authorization,
  Workspaces,
  Payments,
});

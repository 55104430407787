import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BiMobile, BiLock } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { AiFillInfoCircle } from 'react-icons/ai';
import { IoIosKey } from 'react-icons/io';
import {
  Alert,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  StyledLoginForm,
  StyledInputInfo,
  StyledSendVerificationButton,
} from './Login.form.styles';
import { Login, GetWorkspaceId, SendVerificationCode } from '../../../store/actions/Authorization';

function LoginForm({
  Login,
}) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [workspaceId, setWorkspaceId] = useState('');
  const [verificationModal, setVerificationModal] = useState(false);
  const [errorDuringVerificationProcess, setErrorDuringVerificationProcess] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [isVerificationCodeSending, setIsVerificationCodeSending] = useState(false);

  const firstStepInitialValues = {
    username: '',
    password: '',
  };

  const firstStepValidationSchema = Yup.object().shape({
    username: Yup.string().required(t('Required')),
    password: Yup.string().required(t('Required')),
  });

  const secondStepInitialValues = {
    clientName: '',
    workspaceName: '',
    code: '',
  };

  const secondStepValidationSchema = Yup.object().shape({
    clientName: Yup.string().required(t('Required')),
    workspaceName: Yup.string().required(t('Required')),
    code: Yup.string().required(t('Required')),
  });

  const handleToggle = () => {
    if (verificationModal) {
      setUsername('');
      setPassword('');
      setWorkspaceId('');
      setErrorDuringVerificationProcess(false);
      setVerificationCodeSent(false);
    }
    setVerificationModal(!verificationModal);
  };

  const handleFirstStepLogin = (values) => {
    setUsername(values.username);
    setPassword(values.password);
    handleToggle();
  };

  const handleSecondStepLogin = (values) => {
    setErrorDuringVerificationProcess(false);

    const response = Login({
      username,
      password,
      workspaceId,
      code: values.code,
    });

    if (!response) {
      setErrorDuringVerificationProcess(true);
    }
  };

  const handleSendVerificationCode = async (values) => {
    // Reset error status
    setErrorDuringVerificationProcess(false);
    setIsVerificationCodeSending(true);

    const workspaceId = await GetWorkspaceId(values.clientName, values.workspaceName);

    if (!workspaceId) {
      setErrorDuringVerificationProcess(true);
      return;
    }

    setWorkspaceId(workspaceId);

    const verificationCodeResponse = await SendVerificationCode({
      username,
      workspaceId,
    });

    if (verificationCodeResponse.status === 201) {
      setVerificationCodeSent(true);
    }
    setIsVerificationCodeSending(false);
  };

  return (
    <StyledLoginForm>
      <Formik
        initialValues={firstStepInitialValues}
        onSubmit={handleFirstStepLogin}
        validationSchema={firstStepValidationSchema}
      >
        {({
          handleChange, values, errors, touched,
        }) => (
          <Form>
            <FormGroup className="position-relative">
              <Label for="username">{t('Your login (mobile number)')}</Label>
              <Input
                className="icon"
                type="text"
                id="username"
                placeholder={t('Enter your mobile number')}
                onChange={handleChange}
                value={values.username}
                invalid={touched.username && errors.username}
              />
              <BiMobile />
              <FormFeedback>{errors.username}</FormFeedback>
            </FormGroup>

            <FormGroup className="position-relative">
              <Label for="password">{t('Password')}</Label>
              <Input
                className="icon"
                type="password"
                id="password"
                placeholder={t('Enter your password')}
                onChange={handleChange}
                value={values.password}
                invalid={touched.password && errors.password}
              />
              <BiLock />
              <StyledInputInfo>
                <AiFillInfoCircle />
                {t('Password will be created via TokLok app. Please follow link we sent in message on your mobile device')}
              </StyledInputInfo>
              <FormFeedback>{errors.password}</FormFeedback>
            </FormGroup>

            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
            >
              {t('Log in')}
            </Button>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={verificationModal}
        toggle={handleToggle}
      >
        <ModalHeader
          toggle={handleToggle}
        >
          {t('User verification')}
        </ModalHeader>

        <Alert color="danger" isOpen={errorDuringVerificationProcess} fade={false}>
          {t('Verification process failed. Please check provided data.')}
        </Alert>

        <Formik
          initialValues={secondStepInitialValues}
          onSubmit={handleSecondStepLogin}
          validationSchema={secondStepValidationSchema}
        >
          {({
            handleChange, values, errors, touched,
          }) => (

            <Form>
              <ModalBody>
                <FormGroup className="position-relative">
                  <Label for="clientName">{t('Client name')}</Label>
                  <Input
                    className="icon"
                    type="text"
                    id="clientName"
                    placeholder={t('Enter your client name')}
                    onChange={handleChange}
                    value={values.clientName}
                    invalid={touched.clientName && errors.clientName}
                  />
                  <FiUser />
                  <FormFeedback>{errors.clientName}</FormFeedback>
                </FormGroup>

                <FormGroup className="position-relative">
                  <Label for="workspaceName">{t('Workspace name')}</Label>
                  <Input
                    className="icon"
                    type="text"
                    id="workspaceName"
                    placeholder={t('Enter workspace name')}
                    onChange={handleChange}
                    value={values.workspaceName}
                    invalid={touched.workspaceName && errors.workspaceName}
                  />
                  <FiUser />
                  <FormFeedback>{errors.workspaceName}</FormFeedback>
                  <StyledInputInfo>
                    <AiFillInfoCircle />
                    {t('If you have multiple workspaces assigned to your account, type name for one of them')}
                  </StyledInputInfo>
                </FormGroup>

                {
                  !verificationCodeSent && (
                  <StyledSendVerificationButton>
                    <Button
                      type="button"
                      color="secondary"
                      outline
                      size="lg"
                      block
                      onClick={() => handleSendVerificationCode(values)}
                      disabled={
                        !values.clientName
                        || !values.workspaceName
                        || isVerificationCodeSending
                      }
                    >
                      {
                        !isVerificationCodeSending && (
                          t('Send verification code')
                        )
                      }

                      {
                          isVerificationCodeSending && (
                          <div className="lds-ellipsis lds-ellipsis-button">
                            <div />
                            <div />
                            <div />
                            <div />
                          </div>
                          )
                      }
                    </Button>
                  </StyledSendVerificationButton>
                  )
                }

                {
                  verificationCodeSent && (
                  <Alert color="success" isOpen fade={false}>
                    {t('Message with verification code sent.')}
                  </Alert>
                  )
                }
                <FormGroup className="position-relative">
                  <Label for="code">{t('Verification code')}</Label>
                  <Input
                    className="icon"
                    type="text"
                    id="code"
                    placeholder={t('Enter your verification code')}
                    onChange={handleChange}
                    value={values.code}
                    invalid={touched.code && errors.code}
                  />
                  <IoIosKey />
                  <FormFeedback>{errors.code}</FormFeedback>
                  <StyledInputInfo>
                    <AiFillInfoCircle />
                    {t('We send verification code on your phone number')}
                  </StyledInputInfo>
                </FormGroup>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                >
                  {t('Log in')}
                </Button>
              </ModalFooter>

            </Form>
          )}
        </Formik>
      </Modal>
    </StyledLoginForm>
  );
}

LoginForm.propTypes = {
  Login: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  Login: (data) => dispatch(Login(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);

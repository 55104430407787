import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Routes from './router/routes';
import Header from './components/common/Header/Header.component';
import Footer from './components/common/Footer/Footer.component';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import './App.scss';

function App() {
  return (
    <>
      <Header />
      <Routes />
      <Footer />
      <ToastContainer position="bottom-right" />
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthorized: !!state.Authorization.authorization_data,
});

export default connect(
  mapStateToProps,
  null,
)(App);

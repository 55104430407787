import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumb, BreadcrumbItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/config';

import {
  StyledBreadcrumb,
} from './Breadcrumb.styles';

function BreadcrumbComponent({
  active,
}) {
  const [current, setCurrent] = useState(routes.BASE_PATH);
  const { t } = useTranslation();

  const locationMap = {
    '/login': t('Log in'),
    '': t('Log in'),
    '/dashboard': t('Dashboard'),
    '/workspaces': t('Workspaces'),
    '/account': t('Account'),
    '/settings': t('Settings'),
    '/payments': t('Payments'),
  };

  useEffect(() => {
    setCurrent(locationMap[active]);
  }, [active]);

  return (
    <StyledBreadcrumb>
      <Breadcrumb>
        <BreadcrumbItem>
          {t('Home')}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          { current }
        </BreadcrumbItem>
      </Breadcrumb>
    </StyledBreadcrumb>
  );
}

BreadcrumbComponent.propTypes = {
  active: PropTypes.string.isRequired,
};

export default BreadcrumbComponent;

import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../common/PageTitle/PageTitle.component';

function SettingsView() {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <PageTitle title={t('Settings')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} />
      </Row>
    </Container>
  );
}

export default SettingsView;

import styled from 'styled-components';

export const StyledPasswordResetView = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    
    > div {
        flex: 1 1 auto;
    }
    
    .fill-page {
        height: 100%;
    }
`;

export const StyledStyledPasswordImage = styled.div`
    background: #F7F7F7;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
`;

export const StyledStyledPasswordFormWrapper = styled.div`
    padding: 0 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: calc(500px + 20%);
    box-sizing: border-box;
`;

export const StyledTitle = styled.h3`
    color: #545DA9;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    
    &:after {
        margin-top: 3px;
        display: block;
        content: '';
        border-bottom: 4px solid #545DA9;
        width: 80px;
    }
`;

export const StyledSubtitle = styled.p`
    color: #1D2131;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
`;
